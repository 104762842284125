/* eslint-disable @typescript-eslint/no-explicit-any */

import { QueryClient, useMutation } from "@tanstack/react-query";
import { signUp } from "@/pages/Authentication/AuthUtils/Signup";
import { DevUser } from "openapi/api/model";
import { usePostUserDeveloper } from "../../../openapi/api/endpoints/default/default";
import { useState } from "react";
import { AxiosError } from "axios";

/**
 * @Session How it works =>
 * for a given parent and child route, if there is a match on a child route, the beforeLoad for 
 * both the parent AND the child routes will fire (parent then child's) THEN load fires for
 * both in that sequence
 * @usage Use beforeLoad of parent component to validate authentication, then the child 
 * beforeLoad to manage accessToken lifecycle (ensure API calls do not bounce), then parent load 
 * to relevant queryData then childs - this also promotes better separation of concerns
 * @param queryClient instance 
 */



/**
 * Account creation logic for when user is added through sharable link
 * Normal SignIn does not require this as without a link, we create dev users on the purchase of a license
 * @1-Logs in in cognito and sets token in cache
 * @2-Creates devUser when cognito login is successful
 */
export const useCreateAccount=({queryClient,devUser,onSuccess}:{queryClient:QueryClient,devUser?:DevUser,onSuccess:()=>void})=>{
    const [loading, setLoading]=useState(false)
    const [errors, setErrors]=useState<void|Error>()
    const {data:newUserId,mutateAsync:createUserDeveloper}=usePostUserDeveloper({
        mutation: {
            onSuccess,
            onSettled:()=>{
                queryClient.invalidateQueries({queryKey: [`/user-developer`]})
            }
        }
    })
    
    const createAccount = async() => {
        try{
            setLoading(true)
            if(!queryClient.getQueryData(['Auth'])||!devUser)return
            await createUserDeveloper({data:devUser})
            setLoading(false)
        }catch(err){
            setLoading(false)
            setErrors(new Error((err as AxiosError).message))
            throw err
        }
    }
    return {
        createAccount,
        errors:errors,
        loading,
        newUserId
    }
}

export const useCreateDev=({queryClient,devUser,onSuccess}:{queryClient:QueryClient,devUser?:DevUser,onSuccess:()=>void})=>{
    
    const [loading, setLoading]=useState(false)
    const [errors, setErrors]=useState<void|Error>()
    const {data:newUserId,mutateAsync:createDeveloper}=usePostUserDeveloper({
        mutation: {
            onSuccess,
            onSettled:()=>{
                queryClient.invalidateQueries({queryKey: [`/user-developer`]})
            }
        }
    })
    
    const createDev = async() => {
        try{
            setLoading(true)
            if(!queryClient.getQueryData(['Auth'])||!devUser)return
            await createDeveloper({data:devUser})
            setLoading(false)
        }catch(err){
            setLoading(false)
            setErrors(new Error((err as AxiosError).message))
            throw err
        }
    }
    return {
        createDev,
        errors:errors,
        loading,
        newUserId
    }
}




    
/* export const useSignUp=()=>{
    return useMutation({
        mutationFn:signUp
    })
} */

type useSignUpProps={
    onFailureFunc?:(err?: Error)=>void
}
        
export const useSignUp=(onFailure?:useSignUpProps)=>{
    return useMutation({
        mutationFn:signUp,
        onError:(err)=>{
            if(!onFailure || !onFailure.onFailureFunc)return
            onFailure.onFailureFunc(err)
            throw ({useSignupErr:err})
        }
    })
}