/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthCard } from "@/components/Auth/AuthCard"
import { LoginSchema } from "@/utils/FormUtils/FormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useGetUserDeveloper } from "../../../../openapi/api/endpoints/default/default";
import { toast } from "sonner";
import { useLoginExperimental } from "../AuthUtils/authHooks";
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";

export const Login = () => {
  const navigate=useNavigate({from:'/auth'})
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const { register, handleSubmit, formState: {errors} } = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      userName: "",
      password: "",
    }});
  
  const {refetch:getUser,isFetching}=useGetUserDeveloper({
    query:{
      enabled:false,
      refetchOnWindowFocus:false,
      retry:false
    }
  })

  

  const {mutateAsync:SignIn,isPending}=useLoginExperimental({onSuccess:()=>{
    navigate({to:'/home'})
  }})
  const _triggerSubmit = () => {
    submitRef?.current?.click();
  };
  const submitRef = useRef(null) as any

  const onSubmit = async (data:{userName:string,password:string}) => {
    try{
      await SignIn({
        userName:data.userName,
        password:data.password
      })
      const user = await getUser()
      if(user.status=='error'){
        throw user.error.response?.data
      } 
    }catch(err){
      toast.error("Unable to login due to an error!",{
        description: `${err}`,
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#dc2626'
        }
      })
    }

  };


  const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
  }

  
  return (
    <AuthCard 
    loading={isFetching || isPending}
    cardTitle="Log in"
    cardDescription="Enter your username and password to log into your account"
    buttonText="Log-in"
    onSubmit={_triggerSubmit}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid w-full items-center gap-4">
          <div className="flex flex-col space-y-1.5" key="userName">
            <Label htmlFor="userName">Username</Label>
            <Input id="userName" type="text" {...register("userName")} name="userName"/>
            {errors["userName"] && errors["userName"].message && <p className="text-red-500 text-sm">{errors["userName"].message}</p>}
          </div>
          <div className="flex flex-col space-y-1.5" key="password">
            <Label htmlFor="password">Password</Label>
            <div className="flex">
              <Input id="password" type={`${type}`} {...register("password")} name="password"/>
              <span className="flex justify-around items-center" onClick={handleToggle}>
                <Icon className="absolute mr-10" icon={icon} size={15}/>
              </span>
            </div>
            {errors["password"] && errors["password"].message && <p className="text-red-500 text-sm">{errors["password"].message}</p>}
          </div>
        </div>
        <input type="submit" value="Submit" style={{ display: 'none' }} ref={submitRef} />
      </form>
    </AuthCard>
  )
}
