import { getUser } from './GetUser';
import { ClientMetadata } from 'amazon-cognito-identity-js';
// This is the same user pool we created in the above step

export type PasswordResetProps={
  name: string,
  email: string,
  password?: string,
  phone_number?: string,
  metaData?: ClientMetadata,
}

export type PasswordResetResponse={
  status: 'success'|'error',
  message: Error | any

}
export const forgotPassword=async({username}:{username:string}):Promise<PasswordResetResponse>=>{
  // const poolData = { UserPoolId: xxxx, ClientId: xxxx };
  // userPool is const userPool = new AWSCognito.CognitoUserPool(poolData);

  // setup cognitoUser first
  const cognitoUser = getUser({userName:username});

  // call forgotPassword on cognitoUser
  return await new Promise((resolve,reject)=>{
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
          console.log('call result: ' + result);
          resolve({status:'success',message:result})
      },
      onFailure: (err) => {
          alert(err);
          reject({status:'Error',message:err})
      },
      
    });
  })
  
}

/* const inputVerificationCode=()=> { // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
  const verificationCode = prompt('Please input verification code ', '');
  const newPassword = prompt('Enter new password ', '');
  cognitoUser.confirmPassword(verificationCode as string, newPassword as string, this);
} */

// confirmPassword can be separately built out as follows...  
export function confirmPassword({username, verificationCode, newPassword}:{username:string, verificationCode:string, newPassword:string}) {
  const cognitoUser = getUser({userName:username});

  return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
          onFailure(err) {
            console.log('Error: ',err);
              reject(err);
          },
          onSuccess(success) {
            console.log('Success: ',success);
              resolve(success);
          },
      });
  });
} 